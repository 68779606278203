import React, { useContext } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { setUser } from '@shared/utils/auth';
import firebase from 'gatsby-plugin-firebase';
import { LoggedInContext } from '@shared/context';

const Login = () => {
  const { setLoggedIn } = useContext(LoggedInContext);

  function getUiConfig(auth) {
    return {
      signInFlow: 'popup',
      signInOptions: [auth.GoogleAuthProvider.PROVIDER_ID, auth.EmailAuthProvider.PROVIDER_ID],
      // signInSuccessUrl: '/profile',
      callbacks: {
        signInSuccessWithAuthResult: (result) => {
          setUser(result.user, () => setLoggedIn(result.user));
        },
      },
    };
  }

  return (
    <div className="w-64">
      {firebase && (
        <StyledFirebaseAuth uiConfig={getUiConfig(firebase.auth)} firebaseAuth={firebase.auth()} />
      )}
    </div>
  );
};

export default Login;
