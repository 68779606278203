import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const Modal = ({ title, open, canClose, onCloseClick, children }) => {
  const handleClose = () => {
    onCloseClick();
  };

  return (
    <Dialog aria-labelledby="login-dialog" open={open} maxWidth="lg">
      {title && (
        <DialogTitle id="login-dialog">
          <Typography align="center">{title}</Typography>
          {canClose ? (
            <IconButton
              className="mr-1 mt-1 absolute top-0 right-0 text-gray-500"
              aria-label="close"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
      )}
      {children}
    </Dialog>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  canClose: PropTypes.bool,
  onCloseClick: PropTypes.func,
};

Modal.defaultProps = {
  canClose: false,
  onCloseClick: () => {},
};

export default Modal;
